import React, { useState, useEffect, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  // Add your Firebase config here
  apiKey: "AIzaSyDfFouAsk1PRToocz1-kYHlaWxKcs5l0mk",
  authDomain: "new1-7ebce.firebaseapp.com",
  projectId: "new1-7ebce",
  storageBucket: "new1-7ebce.appspot.com",
  messagingSenderId: "444441103446",
  appId: "1:444441103446:web:a9440b8362037a6e73bff7",
  measurementId: "G-S473DJ6EK3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const TerminalPage = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState([]);
  const terminalRef = useRef(null);

  useEffect(() => {
    setOutput(['Enter your email to exit the Game Development Hell:']);
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [output]);

  const handleInput = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const fullPrompt = `$ ${input}`;
      setOutput(prevOutput => [...prevOutput, fullPrompt]);
      await processInput(input);
      setInput('');
    }
  };

  const processInput = async (input) => {
    if (isValidEmail(input)) {
      try {
        console.log('Saving email to Firebase:', input);
        const docId = await saveEmailToFirebase(input);
        setOutput(prevOutput => [...prevOutput, `Email saved successfully with ID: ${docId}. Exiting the Matrix...`, 'Goodbye.']);
        // Here you might want to redirect the user or change the app state
        setTimeout(() => {
          // Add any cleanup or redirection logic here
          console.log('Exiting the Matrix...');
        }, 2000);
      } catch (error) {
        console.error("Error in processInput:", error);
        setOutput(prevOutput => [...prevOutput, `Error saving email: ${error.message}`, 'Please try again.']);
      }
    } else {
      setOutput(prevOutput => [...prevOutput, 'Invalid email. Please enter a valid email address.']);
    }
  };

  const isValidEmail = (email) => {
    return email.includes('@');
  };

  const saveEmailToFirebase = async (email) => {
    try {
      const docRef = await addDoc(collection(db, "emails"), {
        email: email,
        timestamp: serverTimestamp()
      });
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  };

  return (
    <div className="bg-black min-h-screen flex items-center justify-center p-4 font-mono">
      <div className="bg-black border-2 border-green-500 rounded-lg shadow-lg w-full max-w-xl h-[60vh] flex flex-col">
        <div className="bg-green-500 p-1">
          <span className="text-black text-sm font-bold">Khelbe Terminal</span>
        </div>
        <div 
          ref={terminalRef}
          className="flex-grow p-4 overflow-y-auto text-green-500"
          style={{
            backgroundColor: 'rgba(0, 20, 0, 0.7)',
          }}
        >
          {output.map((line, index) => (
            <div key={index} className="mb-1 whitespace-pre-wrap">{line}</div>
          ))}
          <div className="flex">
            <span>$ </span>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleInput}
              className="bg-transparent outline-none flex-grow ml-1 text-green-500"
              autoFocus
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerminalPage;