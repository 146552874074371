import React from 'react';
import { MatrixBackground } from './App';
import gameScreenshot from './game-shot.jpeg'; // Adjust the path as needed

const Videos = () => {
  const handleImageClick = () => {
    window.location.href = 'Games.html';
  };

  return (
    <div className="relative min-h-screen">
      <MatrixBackground isActive={true} />
      <div className="absolute inset-0 flex flex-col items-center justify-center p-6">
        <div id="videos" className="mb-6 flex justify-between w-full px-10">
          <iframe width="45%" height="300" src="https://www.youtube.com/embed/zTjf-I8yazU?si=9Ln2zVCgiw2I8nTL" title="YouTube video 1" frameBorder="0" allowFullScreen></iframe>
          <iframe width="45%" height="300" src="https://www.youtube.com/embed/GnB9rQYcHWk?si=F3NRsp548STY21KL" title="YouTube video 2" frameBorder="0" allowFullScreen></iframe>
        </div>
        <div className="mt-8">
          <img 
            src={gameScreenshot} 
            alt="Click to play game" 
            width="600" 
            // height="335" 
            onClick={handleImageClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Videos;