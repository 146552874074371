import React, { useState, useEffect, useRef } from 'react';
import TerminalPage from './Terminal';
import Game from './Videos';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

export const MatrixBackground = ({ isActive }) => {
  const canvasRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    const columns = Math.floor(canvas.width / 20);
    const drops = Array(columns).fill(1);
    const matrix = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()_+';
    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#0F0';
      ctx.font = '15px monospace';
      for (let i = 0; i < drops.length; i++) {
        const text = matrix[Math.floor(Math.random() * matrix.length)];
        ctx.fillText(text, i * 20, drops[i] * 20);
        if (drops[i] * 20 > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
      if (isActive) {
        animationFrameIdRef.current = requestAnimationFrame(draw);
      }
    };
    if (isActive) {
      draw();
    }
    return () => {
      cancelAnimationFrame(animationFrameIdRef.current);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [isActive]);
  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1
      }}
    />
  );
};
const BlueScreenOfDeath = () => (
  <div className="fixed inset-0 bg-blue-600 z-50 flex items-center justify-center">
    <div className="text-white font-mono text-left max-w-2xl p-8">
      <h1 className="text-3xl mb-4">:(</h1>
      <p className="mb-4">Your PC ran into a problem and needs to restart. We're just collecting some error info, and then we'll restart for you.</p>
      <p className="mb-4">0% complete</p>
      <p className="mb-8">
        For more information about this issue and possible fixes, visit https://www.windows.com/stopcode
      </p>
      <p>If you call a support person, give them this info:</p>
      <p>Stop code: SYSTEM_SERVICE_EXCEPTION</p>
    </div>
  </div>
);
const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/terminal" element={<TerminalPage />} />
        <Route path="/game" element={<Game />} />
      </Routes>
    </Router>
  );

  // const [showTerminal, setShowTerminal] = useState(false);

  // return (
  //   <>
  //     {showTerminal ? (
  //       <TerminalPage />
  //     ) : (
  //       <MainContent setShowTerminal={setShowTerminal} />
  //     )}
  //   </>
  // );
};

const MainContent = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [showBSOD, setShowBSOD] = useState(false);

  const handleAgree = () => {
    console.log("User agreed");
    navigate('/terminal');
    // setShowTerminal(true);
  };

  const handleDisagree = () => {
    setIsActive(false);
    setTimeout(() => {
      setShowBSOD(true);
    }, 500);
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <MatrixBackground isActive={isActive} />
      {!showBSOD && (
        <div className="z-20 text-center">
          <div className="mb-8 flex justify-center">
            <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/MxL3oKau_FI?si=ZJi4_dnsZFJrORbG" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </div>
          <h1 className="text-green-500 text-2xl mb-6 font-mono">
            What if I told you that game development is broken..
          </h1>
          <div className="flex justify-center space-x-4">
            <button 
              onClick={handleAgree}
              className="bg-red-600 text-white px-6 py-2 rounded-full hover:bg-red-700 transition duration-300 w-28 transform hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl"
            >
              Agree
            </button>
            <button 
              onClick={handleDisagree}
              className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300 w-28 transform hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl"
            >
              Disagree
            </button>
          </div>
        </div>
      )}
      {showBSOD && <BlueScreenOfDeath />}
      <Link
        to="/game"
        className="absolute text-center top-4 right-4 bg-green-600 text-black px-6 py-2 rounded-full hover:bg-green-700 transition duration-300 w-28 transform hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl"
      >
        Games
      </Link>
    </div>
  );
};

export default App;